"use client";

import { HTMLProps } from "react";
import NextLink, { LinkProps } from "next/link";

import { useSitePrefix } from "@/hooks/sitePrefix";

type Props = LinkProps & HTMLProps<HTMLAnchorElement>;

const Link = (props: Props) => {
  const sitePrefix = useSitePrefix();

  const href = `${props.href}`.includes("://")
    ? props.href
    : `${sitePrefix ? `/${sitePrefix}` : ""}${props.href}`;

  return <NextLink {...props} href={href} />;
};

export default Link;
