import { useEffect, useState } from "react";
import { sites } from "cms/sites";
import { usePathname } from "next/navigation";

export function useSitePrefix() {
  const [prefix, setPrefix] = useState<string>("");
  const pathname = usePathname();

  useEffect(() => {
    const [, pathPrefix] = pathname.match(/\/([^/]+)/) ?? [];

    if (!pathPrefix) {
      return;
    }

    if (pathPrefix === prefix) {
      return;
    }

    if (!sites.includes(pathPrefix as any)) {
      return;
    }

    setPrefix(pathPrefix);
  }, [pathname, prefix]);

  return prefix;
}
