import type { ReactNode } from "react";
import { BodyText, Heading } from "@aidnas/design-system";

import styles from "./Styles.module.scss";

import Image from "@/components/Image";
import Link from "@/components/Link";
import BEMHelper from "@/lib/bem";
import { getFirstPageInSection } from "@/lib/sections";
import { SiteQueryResult } from "@/types/sanity";

type HeaderProps = {
  sections?: NonNullable<SiteQueryResult>["sections"];
  nonInteractive?: boolean;
};

const bem = BEMHelper(styles);

function SectionWrapper({ children, href, ...props }: { href?: string; children: ReactNode }) {
  if (href) {
    return (
      <Link {...props} href={href}>
        {children}
      </Link>
    );
  }

  return <div {...props}>{children}</div>;
}

export default function Sections({ nonInteractive = false, sections }: HeaderProps) {
  return (
    <div {...bem("")}>
      {sections
        ?.filter((section) => !section.hidden)
        ?.map((section) => {
          const firstPage = getFirstPageInSection(section);

          return (
            <SectionWrapper
              key={section._key}
              href={
                nonInteractive
                  ? undefined
                  : `/${section?.slug?.current}/${firstPage?.slug?.current}`
              }
              {...bem("item")}
            >
              <Image height={118} image={section.image!} width={320} />
              <Heading level={3} size="md" {...bem("title")}>
                {section.title}
              </Heading>
              <BodyText size="md" {...bem("description")}>
                {section.description}
              </BodyText>
            </SectionWrapper>
          );
        })}
    </div>
  );
}
