import { Display, Paragraph } from "@aidnas/design-system";

import styles from "./Styles.module.scss";

import Sections from "@/components/Sections";
import BEMHelper from "@/lib/bem";
import type { SiteQueryResult } from "@/types/sanity";

type Props = {
  data: SiteQueryResult;
};

const bem = BEMHelper(styles);

export function HomePage({ data }: Props) {
  if (!data) {
    return null;
  }

  return (
    <div {...bem("")}>
      <Display level={1} size="md">
        {data.title}
      </Display>

      <Paragraph
        style={{
          maxWidth: "705px",
          fontSize: "var(--font-size-large)",
          marginBottom: "var(--spacing-900)",
        }}
      >
        {data?.description}
      </Paragraph>

      <Sections sections={data?.sections} />
    </div>
  );
}
