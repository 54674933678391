import NextImage from "next/image";

import { urlForImage } from "@/sanity/utils";

interface ImageBoxProps {
  "image"?: { asset?: any };
  "alt"?: string;
  "width"?: number;
  "height"?: number;
  "size"?: string;
  "classesWrapper"?: string;
  "data-sanity"?: string;
}

export default function Image({
  alt = "Hovedbilde",
  classesWrapper,
  height,
  image,
  size,
  width = 1600,
  ...props
}: ImageBoxProps) {
  const originalWidth = image?.asset?.metadata?.dimensions?.width || 1600;
  const calculatedWidth = Math.min(width, originalWidth);
  const imageUrl = urlForImage(image)?.width(calculatedWidth).url();

  if (!imageUrl || !image?.asset) return null;

  return (
    <div
      className={`relative ${classesWrapper || ""}`}
      style={{
        lineHeight: 0,
        aspectRatio: width && height ? `${width} / ${height}` : "auto",
      }}
    >
      <NextImage
        alt={alt}
        className="object-contain"
        height={height || 100}
        sizes={size}
        src={imageUrl}
        style={{
          height: "auto",
          width: calculatedWidth,
          margin: "0 auto",
        }}
        width={calculatedWidth}
        {...props}
      />
    </div>
  );
}
