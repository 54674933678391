import { groq } from "next-sanity";

const linkRef = `
  "reference": {
    "type": @.reference->_type,
    "section": @.reference->site->sections[count(sidebar[count(docPages[_ref == ^.^.^.reference->_id])>0])>0][0].slug.current,
    "slug": @.reference->slug.current,
    "site": @.reference->site._ref
  }
`;

const block = `
  ...,
  markDefs[]{
    ...,
    _type == "link" => {${linkRef}},
    _type == "linkBlock" => {${linkRef}},
  }
`;

const bodyBlocks = `
  ...,
  _type == "imageBlock" => {
    ...,
    asset->,
    caption[]{${block}}
  },
  _type == "componentProps" => {
    items[] {
      ...,
      type[]{${block}}
    }
  },
  _type == "linkBlock" => {
    ...,
    ${linkRef}
  },
  _type == "docCallout" => {
    ...,
    body[]{${block}}
  },
  _type == "block" => {${block}}
`;

export const siteQuery = groq`*[_type == "site" && _id == $site][0]{
  ...,
  sections[]{
    _key,
    slug,
    title,
    hidden,
    description,
    image,
    sidebar[]{
      ...,
      _key,
      docPages[]->{
        _id,
        slug,
        title,
        label
      }
    },
  },
  headerNav[]{
    ...,
    _key,
    ${linkRef}
  }
}`;

export const pageQuery = groq`(
  *[
    _type == 'site' && 
    _id == $site
  ][0].sections[
    slug.current == $section
  ][0].sidebar[].docPages[]->)[
    slug.current == $slug
  ][0]{
    _id,
    _type,
    title,
    preamble,
    showCallout,
    callout{
      ...,
      body[]{${block}}
    },
    intro[]{${block}},
    meta,
    tabs[]{
      _key,
      _type,
      title,
      slug,
      intro[]{${block}},
      body[]{${bodyBlocks}}
    }
  }`;
