/**
 * 🚨 When adding new site keys, take care in selecting a key that you don't want to
 * use in the slug for one of the sections. For example using "content" as the key
 * for a site would make it difficult for the frontend code to know if the first part
 * of the URL /content is the site prefix or the slug of the section. Using "brand-content"
 * would probably be better..
 */

// define the mapping between hostname and site name
export const siteMapping: Record<
  string,
  "designsystem" | "wip-designsystem" /*| "brand-content"*/
> = {
  "designsystem.aidn.no": "designsystem",
  "wip-designsystem.aidn.no": "wip-designsystem",
  // "content.aidn.no": "brand-content",
};

// extract the Site type and sites values
export type Site = (typeof siteMapping)[0];
export const sites = Object.values(siteMapping);

/**
 * Get site for the given hostname. Defaults to designsystem
 */
export const domainSiteMapping = (hostname: string): Site => {
  return siteMapping[hostname] || ("designsystem" satisfies Site);
};

export const siteDomainMapping = (site: string) => {
  return Object.keys(siteMapping).find((domain) => domainSiteMapping(domain) === site);
};
