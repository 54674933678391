"use client";

import { type QueryResponseInitial, useQuery } from "@sanity/react-loader";

import { HomePage } from "./HomePage";

import { siteQuery } from "@/sanity/queries";
import { SiteQueryResult } from "@/types/sanity";

type Props = {
  params: { site: string };
  initial: QueryResponseInitial<SiteQueryResult | null>;
};

export default function HomePagePreview(props: Props) {
  const { initial, params } = props;
  const { data } = useQuery<SiteQueryResult | null>(siteQuery, params, {
    initial,
  });

  return <HomePage data={data!} />;
}
