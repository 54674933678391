import { SiteQueryResult } from "@/types/sanity";

export function getFirstPageInSection(
  section: NonNullable<NonNullable<SiteQueryResult>["sections"]>[0]
) {
  const firstPage = section?.sidebar?.find((sidebarSection) => sidebarSection?.docPages?.length)
    ?.docPages?.[0];

  return firstPage;
}

export function cleanSections(sections?: NonNullable<SiteQueryResult>["sections"]) {
  return (
    sections
      // ensure that the sections have a slug and at least one page with a slug
      ?.filter(
        (section) =>
          section.slug &&
          section.sidebar?.some((sidebarSection) =>
            sidebarSection.docPages?.some((page) => page.slug?.current)
          )
      )

      // filter out sidebar sections with no pages (with slugs)
      .map((section) => ({
        ...section,
        sidebar: section.sidebar
          ?.filter((sidebarSection) => sidebarSection.docPages?.some((p) => p.slug?.current))

          // filter out pages without a slug
          .map((sidebarSection) => ({
            ...sidebarSection,
            docPages: sidebarSection.docPages?.filter((p) => p.slug?.current),
          })),
      }))
  );
}
